import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { Event } from './event';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class EventList extends ContentItem {
  public events!: Elements.LinkedItemsElement<Event>;
  public missingEventsMessage!: Elements.RichTextElement;
  constructor() {
    super({
      propertyResolver: (elementName: string): string => {
        if (elementName === 'missing_events_message') {
          return 'missingEventsMessage';
        }
        return elementName;
      }
    });
  }
}
