import { MenuItem } from './menu_item';
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class MenuCategory extends ContentItem {
  public menuItems!: Elements.LinkedItemsElement<MenuItem>;
  public categoryName!: Elements.TextElement;
  constructor() {
    super({
      propertyResolver: (elementName: string): string => {
        if (elementName === 'menu_items') {
          return 'menuItems';
        }
        if (elementName === 'category_name') {
          return 'categoryName';
        }
        return elementName;
      }
    });
  }
}
