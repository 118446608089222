import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class NavLink extends ContentItem {
  public linkText!: Elements.RichTextElement;
  public linkUrl!: Elements.TextElement;
  constructor() {
    super({
      propertyResolver: (elementName: string): string => {
        if (elementName === 'link_text') {
          return 'linkText';
        }
        if (elementName === 'link_url') {
          return 'linkUrl';
        }
        return elementName;
      }
    });
  }
}
