import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Event extends ContentItem {
  public headerImage!: Elements.AssetsElement;
  public date!: Elements.TextElement;
  public description!: Elements.RichTextElement;
  public headline!: Elements.TextElement;
  constructor() {
    super({
      propertyResolver: (elementName: string): string => {
        if (elementName === 'header_image') {
          return 'headerImage';
        }
        return elementName;
      }
    });
  }
}
