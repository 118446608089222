import { MenuCategory } from './menu_category';
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Menu extends ContentItem {
  public drinks!: Elements.LinkedItemsElement<MenuCategory>;
  public foods!: Elements.LinkedItemsElement<MenuCategory>;
}
