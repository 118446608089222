import { Hours } from './hours';
import { Menu } from './menu';
import { HomePage } from './home_page';
import { AboutPage } from './about_page';
import { ContentItem, Elements } from '@kentico/kontent-delivery';
import { Gallery } from './gallery';
import { Navigation } from './navigation';
import { EventList } from './event_list';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class BasePage extends ContentItem {
  public about!: Elements.LinkedItemsElement<AboutPage>;
  public homePage!: Elements.LinkedItemsElement<HomePage>;
  public menu!: Elements.LinkedItemsElement<Menu>;
  public gallery!: Elements.LinkedItemsElement<Gallery>;
  public hours!: Elements.LinkedItemsElement<Hours>;
  public navigation!: Elements.LinkedItemsElement<Navigation>;
  public events!: Elements.LinkedItemsElement<EventList>;
  constructor() {
    super({
      propertyResolver: (elementName: string): string => {
        if (elementName === 'home_page') {
          return 'homePage';
        }
        return elementName;
      }
    });
  }
}
