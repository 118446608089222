import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Hours extends ContentItem {
  public isClosed!: Elements.MultipleChoiceElement;
  public openTime!: Elements.TextElement;
  public closeTime!: Elements.TextElement;
  public day!: Elements.TextElement;
  constructor() {
    super({
      propertyResolver: (elementName: string): string => {
        if (elementName === 'is_closed') {
          return 'isClosed';
        }
        if (elementName === 'open_time') {
          return 'openTime';
        }
        if (elementName === 'close_time') {
          return 'closeTime';
        }
        return elementName;
      }
    });
  }
}
